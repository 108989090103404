<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="employeeCollection"
        :search="search"
        :sort-by="['name']"
        :loading="loadingTable"
      >

      <template v-slot:item.fullName="{ item }">
    <span 
      v-if="permissionCan('view')"
      class="employee-name"
      @click="handleClickItem(item)"
    >
      {{ item.fullName }}
    </span>
  </template>
        <template v-slot:top>
          <v-toolbar flat>
            <EmployeeModalForm
              :modalData="modalData"
              :permissions="permissions"
              :departmentCollection="departmentCollection"
              :siteCollection="siteCollection"
              :educationCollection="educationCollection"
              :positionCollection="positionCollection"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></EmployeeModalForm>
            <EmployeeMonthlyHoursModal
              :modalData="modalDataMonthlyHours"
              :permissions="permissions"
              :employee="formModel"
              :dates="dates"
              @closeModalForm="handleCloseMonthlyHoursDialog"
              @saveModalForm="handleSaveMonthlyHoursModalForm"
            ></EmployeeMonthlyHoursModal>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template>
        <template v-slot:item.positionTranslated="{ item }">
          <span v-if="item.position">
            {{ $helpers.getTranslated(item.position.translations).name }}
          </span>
        </template>
        <template v-slot:item.departmentTranslated="{ item }">
          <span v-if="item.department">
            {{ $helpers.getTranslated(item.department.translations).name }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="permissionCan('delete')"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.hours="{ item }">
          <v-icon
            v-if="permissionCan('time.view')"
            small
            @click="handleEditMonthlyHours(item)"
          >
            mdi-calendar-multiple-check
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import EmployeeModalForm, { initialFormData } from "./EmployeeModalForm";

import EmployeeMonthlyHoursModal from "./EmployeeMonthlyHoursModal";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export const ENDPOINT = "humanResources/employee/";

export const PERMISSION_TO = "humanResources.employee.";

export default {
  name: "Educations",
  components: {
    EmployeeModalForm,
    DeleteModalDialog,
    EmployeeMonthlyHoursModal,
  },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "employees",
      routePath: "/humanResource/employees/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Employees",
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "Employee",
          value: "fullName",
        },
        {
          text: "Position",
          value: "positionTranslated",
        },
        {
          text: "Department",
          value: "departmentTranslated",
        },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "Monthly hours", value: "hours", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      modalDataMonthlyHours: {
        dialog: false,
        editedId: null,
        fullName: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,

      dialogHours: false,

      dates: [],
    };
  },

  computed: {
    ...mapGetters([
      "employeeCollection",
      "departmentCollection",
      "siteCollection",
      "positionCollection",
      "educationCollection",
      "statuses",
    ]),
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        this.dialogHours = false;
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      "fetchEmployee",
      "fetchDepartment",
      "fetchSite",
      "fetchEducation",
      "fetchPosition",
    ]),

    fetchModel() {
      this.fetchDepartment();
      this.fetchPosition();
      this.fetchSite();
      this.fetchEducation();

      return this.fetchEmployee();
    },

    handleClickItem(item) {
       if (
         this.permissionCan("view") &&
         !this.dialogDelete &&
         !this.modalDataMonthlyHours.dialog
       ) {
         this.modalData.fullName = item.fullName;
         this.$router.push(this.routePath + item.id);
       }
     },

    handleEditMonthlyHours(item) {
      this.modalDataMonthlyHours.dialog = true;
      this.modalDataMonthlyHours.editedId = item.user_id;
      this.modalDataMonthlyHours.fullName = item.fullName;
    },
    handleCloseMonthlyHoursDialog() {
      this.modalDataMonthlyHours.dialog = false;
      this.modalDataMonthlyHours.editedId = null;
      this.modalDataMonthlyHours.fullName = null;
      // this.resetDate();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.fullName = null;
      this.fetchModel();

      if (this.$route.name !== this.routeName)
        this.$router.push({ name: this.routeName });
    },

    handleSaveMonthlyHoursModalForm() {
      this.handleCloseMonthlyHoursDialog();
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
